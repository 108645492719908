.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2962FF; /* Background color */
  z-index: 9999; /* Ensure it overlays other content */
  flex-direction: column; /* Stack spinner and message */
  text-align: center;
}

.spinner {
  border: 8px solid rgba(0, 0, 0, 0.1); /* Light border */
  border-left-color: #ff0000; /* Spinner color */
  border-radius: 50%;
  width: 50px; /* Spinner size */
  height: 50px; /* Spinner size */
  animation: spin 1s linear infinite; /* Spinner animation */
}

.new-year-message {
  font-size: 2rem; /* Font size for the message */
  color: #fff; /* White text color */
  margin-top: 20px;
  font-weight: bold; /* Bold text */
  font-family: 'Arial', sans-serif; /* Clean font for message */
  max-width: 80%;
  padding: 10px;
  overflow: hidden;
  border-right: 4px solid white; /* Cursor like effect */
  white-space: nowrap;
  width: 0;
  animation: typing 4s steps(40) forwards, blink 0.75s step-end infinite; /* Typing effect */
}

/* Spinner rotation animation */
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* Blinking cursor effect */
@keyframes blink {
  50% {
    border-color: transparent;
  }
}
